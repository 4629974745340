<template>
  <div class="detail-page">
    <div class="big-pics">
      <van-swipe :autoplay="8000" lazy-render>
        <van-swipe-item v-for="(url, index) in prewImages" :key="index">
          <img
            :src="url"
            @click="
              showPreview = true;
              startPosition = index;
            "
          />
        </van-swipe-item>
        <template #indicator="{ active, total }">
          <div class="custom-indicator">{{ active + 1 }} / {{ total }}</div>
        </template>
      </van-swipe>
      <van-image-preview
        v-model:show="showPreview"
        :startPosition="startPosition"
        :images="prewImages"
      ></van-image-preview>
    </div>
    <div  class="info detail-content">
      <div class="price">
        <span class="currency">{{ SYSLANG.currency }}</span
        >{{
          ((detail.minPrice != detail.maxPrice
            ? detail.minPrice + "-" + detail.maxPrice
            : detail.minPrice)/100)+".00"
        }}
        <!--<span class="mprice">{{ SYSLANG.currency + detail.MPrice }}</span>-->
      </div>
      <h3>{{ detail.productName }}</h3>
    </div>
    <div v-if="selectAttrs.length > 0" class="detail-content props">
      <span class="title">{{ PAGELANG.choose }}</span>
      <span class="values" @click="showAttr = true">
        <template v-for="(item, index) in selectAttrs" :key="index">
          {{ index > 0 ? "," : "" }}{{ item }}
        </template>
      </span>
    </div>
    <div class="desc-title">{{ PAGELANG.detailtitle }}</div>
    <div class="desc" v-html="content"></div>

    <GoodsProps
      :showAttr="showAttr"
      :GoodsID="goodsid"
      :showBuyButton="true"
      :curSelectAttr="selectAttrs"
      :curBuyCount="buyCount"
      @selectProps="selectProps"
      @closeProps="closeProps"
    />

    <van-action-bar :placeholder="true">
      <van-action-bar-icon
        icon="shop-o"
        :text="PAGELANG.store"
        to="../product"
        :replace="true"
      />
      <van-action-bar-icon icon="cart-o" :text="PAGELANG.cart" to="../cart" />
      <van-action-bar-button
        color="#e4c994"
        type="warning"
        :text="PAGELANG.addcart"
        @click="showAttr = true"
      />
<!--      <van-action-bar-button
        color="#cdaa66"
        type="danger"
        :text="PAGELANG.buynow"
        @click="showAttr = true"
      /> -->
    </van-action-bar>
  </div>

<!--  <div
    class="pc-detail-page"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <div class="base-info" :style="!showDetail ? 'display: none;' : ''">
      <div class="big-pics">
        <div class="preview">
          <el-image
            v-if="curThumb"
            :src="curThumb"
            fit="contain"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="my-swipe">
          <el-carousel
            trigger="hover"
            height="calc(100vw / 1920 * 450)"
            indicator-position="outside"
            :autoplay="false"
            :style="thumbstyle"
            @change="changeBigPic"
          >
            <el-carousel-item
              v-for="(url, index) in detail.PdtImages"
              :key="index"
            >
              <el-image
                style="
                  width: calc(100vw / 1920 * 450);
                  height: calc(100vw / 1920 * 450);
                "
                :src="UPLOADBASE + url"
                fit="contain"
              />
              <img />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="info">
        <h3>{{ detail.PdtName }}</h3>
        <GoodsProps
          :showAttr="showDetail"
          :GoodsID="goodsid"
          :showBuyButton="true"
          :curSelectAttr="selectAttrs"
          :curBuyCount="buyCount"
          @selectProps="selectProps"
          @closeProps="closeProps"
        />
      </div>
    </div>

    <div class="pdt-desc" :style="!showDetail ? 'display: none;' : ''">
      <el-tabs type="border-card" class="demo-tabs">
        <el-tab-pane :label="PAGELANG.detailtitle">
          <div class="desc" v-html="detail.PdtDetail"></div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-empty v-if="!showDetail" :description="SYSLANG.loading" />
  </div> -->
</template>

<script>
import GoodsProps from "../components/GoodsProps.vue";
import {
  showToast,
  ActionBar,
  ActionBarIcon,
  ActionBarButton,
  showLoadingToast,
  closeToast,
} from "vant";
import { getCookie } from "../util";
export default {
  components: {
    GoodsProps,
  },
  data() {
    return {
      goodsid: 0,
      detail: {},
      showPreview: false,
      startPosition: 0,
      showAttr: false,
      selectAttrs: [],
      buyCount: 0,
      curThumb: "",
      thumbstyle: "",
      curPrice: "",
      curStock: 1000,
      skuindex: -1,
      pcShowAttr: false,
      showDetail: false,
	  prewImages:[],
	  content:"",
	  
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.goods;
    this.CARTLANG = this.LANG.pages.cart;
    this.SYSLANG = this.LANG.system;
    this.UPLOADBASE = this.uploadbase;

    this.rootApp.use(ActionBar).use(ActionBarIcon).use(ActionBarButton);

    if (this.$route.query.GoodsID) {
      this.goodsid = this.$route.query.GoodsID;
      if (this.goodsid) {
        this.getGoodsDetail();
      }
    } else {
      closeToast();
    }
  },
  methods: {
    formatMoney(money) {
      if (!money) return "0.00";
      let integer = Math.floor(money).toString();
      let thousands = integer
        .split("")
        .reverse()
        .join("")
        .replace(/(\d{3})/g, "$1.")
        .replace(/,$/, "");

      if (thousands.substr(-1, 1) == ".") {
        thousands = thousands.substring(0, thousands.length - 1);
      }

      return thousands.split("").reverse().join("");
    },
    getGoodsDetail() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });
      this.showDetail = false;
      this.axios
        .get(this.actions.spuDetail,{params:{productId:this.goodsid}})
        .then((response) => {
          closeToast();
		  if(response.data.code!=200){
			      showToast({
			        message: response.message,
			      });
		  }else{
			this.detail=response.data.data;
			this.prewImages=response.data.data.ext.images;
			this.content=response.data.data.ext.content;
			this.showDetail = true;
			this.selectAttrs = Array(response.data.data.propertyInfo.length).fill("");   
		  }
          // let { code, msg, detail } = data;
          // if (code == "SUCCESS") {
          //   if (detail) {
          //     this.showDetail = true;
          //     this.detail = detail;
          //     //console.log(detail.SKUProps);
          //     let selectAttrs = [];
          //     for (let i = 0; i < detail.SKUProps.length; i++) {
          //       selectAttrs.push(detail.SKUProps[i].PropName);
          //     }
          //     this.selectAttrs = selectAttrs;

          //     let thumbstyle = "";
          //     for (let i = 0; i < detail.PdtImages.length; i++) {
          //       thumbstyle +=
          //         "--aog-thumb-" +
          //         i +
          //         ":url('" +
          //         this.UPLOADBASE +
          //         detail.PdtImages[i] +
          //         "');";
          //     }
          //     this.thumbstyle = thumbstyle;

          //     let skutexts = Array();
          //     for (let i = 0; i < detail.SKUData.length; i++) {
          //       let text = "";
          //       for (let j = 2; j < detail.SKUData[i].length - 3; j++) {
          //         if (text != "") {
          //           text += "/";
          //         }
          //         text += detail.SKUData[i][j];
          //       }
          //       skutexts.push(text);
          //     }
          //     this.skutexts = skutexts;

          //     this.curStock = detail.TotalStock;
          //     this.pcShowAttr = true;
          //   } else {
          //     showToast({
          //       message: this.PAGELANG["找不到对应商品信息"],
          //     });
          //   }
          // } else {
          //   showToast({
          //     message:
          //       code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
          //     forbidClick: true,
          //     onClose: () => {
          //       if (code == "NOTLOGIN") {
          //         this.$router.replace({
          //           path: "/login",
          //         });
          //       }
          //     },
          //   });
          // }
        })
        .catch((error) => {
          //请求失败，触发catch中的函数 可省略
          console.log("views.ProductDetail.getGoodsDetail.error", error);
          closeToast();
          //this.getGoodsDetail();
        });
    },
    selectProps(val, thumb) {
      this.selectAttrs = val;
      this.curThumb = thumb;
      console.log(thumb);
    },
    closeProps(val, buycount) {
      this.showAttr = val;
      this.buyCount = buycount;
    },
    changeBigPic() {
      this.curThumb = "";
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .pc-detail-page {
    display: none;
  }
  .detail-page {
    background-color: #f7f7f9;

    --van-action-bar-height: var(--van-tabbar-height);
    --van-action-bar-button-height: 46px;
    --van-font-size-md: 16px;
    --van-action-bar-icon-font-size: 14px;
    --van-action-bar-icon-size: 20px;
    --van-action-bar-icon-width: 52px;
  }
  .big-pics {
    width: 100%;
    font-size: 0;
  }


  .custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 4px 10px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
  }

  .detail-content {
    background-color: #fff;
    margin-bottom: 8px;
    padding: var(--van-card-padding);
  }
  .detail-content.props {
    display: flex;
    align-items: center;
    height: 30px;
    font-size: 14px;
    margin-bottom: 0;
  }

  .detail-content.props .title {
    width: 80px;
    color: #555;
  }

  .detail-content.props .values {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 0;
    flex-grow: 1;
    color: #000;
  }
  .detail-content.props .values::after {
    content: "\e68b";
    font-family: "iconfont";
  }

  .info .price {
    line-height: 36px;
    font-size: 26px;
    font-weight: bold;
    color: #fe3e57;
  }

  .info .price .currency {
    font-size: 16px;
  }
  .info .price .mprice {
    font-size: 16px;
    color: #888;
    font-weight: normal;
    text-decoration: line-through;
  }

  .info h3 {
    margin: 0;
    line-height: 26px;
    font-size: 16px;
  }

  .desc-title {
    display: flex;
    height: 50px;
    font-size: 16px;
    color: #555;
    justify-content: center;
    align-items: center;
  }

  .desc-title::before,
  .desc-title::after {
    content: "";
    display: block;
    width: 60px;
    height: 2px;
  }
  .desc-title::before {
    margin-right: 6px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), #555);
  }
  .desc-title::after {
    margin-left: 6px;
    background: linear-gradient(to left, rgba(0, 0, 0, 0), #555);
  }

  .desc >>> p {
    margin: 0;
  }
  .desc >>> img {
    display: flex;
	width: 100%;
	line-height: 0px;
	margin-top: -1px;
  }
}

@media (min-width: 769px) {
  .detail-page {
    display: none;
  }
  .pc-detail-page {
    padding: calc(100vw / 1920 * 60) calc(100vw / 1920 * 360)
      calc(100vw / 1920 * 70) calc(100vw / 1920 * 360);
    background-color: #f7f7f7;
  }
  .pc-detail-page .base-info {
    display: flex;
    height: calc(100vw / 1920 * 542);
  }
  .pc-detail-page .base-info .big-pics {
    width: calc(100vw / 1920 * 450);
    height: calc(100vw / 1920 * 450);
    position: relative;
    background-color: #fff;
    margin-right: calc(100vw / 1920 * 30);
  }
  .pc-detail-page .base-info .big-pics .my-swipe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .pc-detail-page .my-swipe >>> .el-carousel {
    --el-carousel-indicator-width: calc(100vw / 1920 * 68);
    --el-carousel-indicator-height: calc(100vw / 1920 * 68);
    --el-carousel-indicator-out-color: #fff;
  }
  .pc-detail-page .my-swipe >>> .el-carousel__arrow {
    display: none;
  }
  .pc-detail-page .my-swipe >>> .el-carousel__indicators--outside {
    white-space: nowrap;
  }
  .pc-detail-page .my-swipe >>> .el-carousel__indicators--outside button {
    opacity: 1;
    border: 1px solid #fff;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  .pc-detail-page .my-swipe >>> .el-carousel__indicator.is-active button {
    border: 1px solid #000;
  }
  .pc-detail-page .my-swipe >>> .el-carousel__indicator:nth-child(1) button {
    background-image: var(--aog-thumb-0);
  }
  .pc-detail-page .my-swipe >>> .el-carousel__indicator:nth-child(2) button {
    background-image: var(--aog-thumb-1);
  }
  .pc-detail-page .my-swipe >>> .el-carousel__indicator:nth-child(3) button {
    background-image: var(--aog-thumb-2);
  }
  .pc-detail-page .my-swipe >>> .el-carousel__indicator:nth-child(4) button {
    background-image: var(--aog-thumb-3);
  }
  .pc-detail-page .my-swipe >>> .el-carousel__indicator:nth-child(5) button {
    background-image: var(--aog-thumb-4);
  }
  .pc-detail-page .my-swipe >>> .el-carousel__indicator:nth-child(6) button {
    background-image: var(--aog-thumb-5);
  }
  .pc-detail-page .preview {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .pc-detail-page .base-info .info {
    width: 0;
    flex-grow: 1;
  }
  .pdt-desc {
    --el-border-color: rgba(0, 0, 0, 0);
    --el-bg-color-overlay: rgba(0, 0, 0, 0);
    margin-top: calc(100vw / 1920 * 40);
  }
  .pdt-desc
    >>> .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item.is-active {
    background-color: #fff;
    --el-color-primary: var(--van-card-price-color);
    border-top-color: var(--van-card-price-color);
    border-top-width: 3px;
  }
}
</style>
